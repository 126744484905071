<template>
  <div class="news-page">
    <div class="news-page__wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content">
        <div :class="['news-item', activeId === item.id ? 'active' : '']" v-for="item of newsList" :key="item.id" @click="handleClick(item.id)">
          <div class="date">
            <div class="day">{{item.date.slice(8)}}</div>
            <div class="month">{{item.date.slice(2, 7)}}</div>
          </div>
          <div class="msg">
            <div class="title">{{ item.title }}</div>
            <div class="detail">{{ item.message }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    data() {
      return {
        newsList: [],
        activeId: 0,
      }
    },
    mounted() {
      this.getNewsList()
    },
    methods: {
      getNewsList() {
        axios.get('/api/news/').then(data => {
          this.newsList = data.data
        })
      },
      handleClick(id) {
        if (this.activeId === id) {
          this.activeId = 0
        } else {
          this.activeId = id
        }
      },
    }
  }
</script>

<style lang="less">
  @media screen and (max-width: 1500px) {
    .news-page {
      &__wrapper {
        width: 79% !important;
      }
    }
  }
  .news-page {
    background: #ffffff;
    width: 100%;
    padding: 32px 0;
  
    &__wrapper {
      margin: 0 auto;
      width: 1300px;
    
      .el-breadcrumb {
        margin-bottom: 16px;
        font-size: 18px;
        padding-bottom: 16px;
        border-bottom: 1px solid #cccccc;
      }
    
      .content {
        p {
          letter-spacing: 2px;
          line-height: 28px;
        }
  
        .news-item {
          display: flex;
          border-bottom: 1px solid #d3d3d3;
          padding-bottom: 12px;
          margin: 8px 0;
          cursor: pointer;
          &.active {
            .detail {
              white-space: normal;
            }
          }
    
          .date {
            color: #666666;
            text-align: center;
            margin: 3px 48px 3px 0;
            .day {
              font-size: 30px;
              font-weight: 600;
            }
            .month {
              white-space: nowrap;
              font-size: 18px;
            }
          }
        }
  
        .msg {
          color: #333333;
          width: calc(100% - 64px - 48px);
          .title {
            font-size: 18px;
            letter-spacing: 3px;
            padding: 8px 0;
            font-weight: 600;
          }
          .detail {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 4px;
          }
        }
      }
    }
  }
</style>
